import React from "react";
import { Link, graphql } from "gatsby";
import { Box, Text } from "@chakra-ui/react";
import PostList from "../components/PostList";
import Layout from "../components/layout";

const Tags = ({ pageContext, data }) => {
  const { tag } = pageContext;
  const { nodes, totalCount } = data.allMdx;
  const categoryHeader = `「${tag}」タグの記事(${totalCount}件)`;

  return (
    <Layout>
      <Box maxW="md" mx="auto">
        <Text as="h3">{categoryHeader}</Text>
        {nodes.map((node) => (
          <PostList node={node} />
        ))}
        <Link to="/blog/tags/">タグ一覧ページに戻る</Link>
      </Box>
    </Layout>
  );
};

export default Tags;

export const pageQuery = graphql`
  query ($tag: String!) {
    allMdx(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      totalCount
      nodes {
        frontmatter {
          title
          date(formatString: "YYYY.MM.DD")
          category
          hero_image_alt
          hero_image {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        id
        slug
        body
      }
    }
  }
`;
